import Vue from "vue";
import Vuex from "vuex";
import router from "../router";
import VuexPersistence from "vuex-persist";
import requestIndexSettings from "./modules/requestIndexSettings.js";

Vue.use(Vuex);

let url = window.location.origin;

if (process.env.NODE_ENV == "development") {
    url = "http://localhost:8000";
} else {
    url;
}

export default new Vuex.Store({
    state: {
        contrast_mode: false,
        user: {},
        language: "nl",
        translations: {
            nl: {},
            en: {},
        },
        cards: [],
        selected_card: null,
        show_user_modal: false,
        show_qrcode_modal: false,
        participants: [],
        programs: [],
        budgets: [],
        config_vars: {
            id: 1,
            name: "Gemeenteportaal",
            program_id: null,
            accent_color: "#333333",
            background_image_url: null,
            card_url: null,
            email: "",
            favicon_url: null,
            gtm: null,
            logo_url: null,
            phone_number: "000",
            pre_token: "606436489210",
            privacy_policy_url: "",
            return_url: "",
            secondary_color: "grey",
            shaped_widgets: true,
            primary_color: "#333333",
            groupit_program_id: null,
            linked_portal_instance_id: null,
            max_deposit_amount: null,
            allow_deposit: false,
            care_budget_id: null,
            permission_groups: [],
        },
    },
    mutations: {
        setPrograms(state, payload) {
            state.programs = payload;
        },
        setBudgets(state, payload) {
            state.budgets = payload;
        },
        setPermissionGroups(state, payload) {
            state.config_vars.permission_groups = payload;
        },
        auth_request(state) {
            state.status = "loading";
        },
        auth_success(state, token) {
            state.status = "success";
            state.token = token;
        },
        auth_error(state) {
            state.status = "error";
        },
        logout(state) {
            localStorage.removeItem("token");
            state.user = {};
            state.selected_card = null;
            state.cards = null;
            state.participants = [];
        },
        setUser(state, payload) {
            state.user = payload;
        },
        setContrastMode(state, payload) {
            state.contrast_mode = payload;
        },
        setCards(state, payload) {
            state.cards = payload;
        },
        setSelectedCard(state, payload) {
            state.selected_card = payload;
        },
        setShowUserModal(state, payload) {
            state.show_user_modal = payload;
        },
        setShowQrCodeModal(state, payload) {
            state.show_qrcode_modal = payload;
        },
        setSelectedCardBalance(state, payload) {
            state.selected_card.balance = payload;
        },
        setParticipants(state, payload) {
            state.participants = payload;
        },
        reset(state) {
            state.selected_card = null;
            state.user = {};
            state.cards = [];
            state.participants = [];
            localStorage.removeItem("token");
        },
        setTranslations(state, payload) {
            state.translations = payload;
        },
        setConfigVars(state, payload) {
            state.config_vars = payload;
        },
    },
    actions: {
        setPrograms({ commit }, data) {
            commit("setPrograms", data);
        },
        setBudgets({ commit }, data) {
            commit("setBudgets", data);
        },
        setPermissionGroups({ commit }, data) {
            commit("setPermissionGroups", data);
        },
        logout({ commit }) {
            return new Promise((resolve) => {
                commit("logout");
                router.push("/login");
                resolve();
            });
        },
        setUser({ commit }, data) {
            commit("setUser", data);
        },
        setContrastMode({ commit }, data) {
            commit("setContrastMode", data);
        },
        setCards({ commit }, data) {
            commit("setCards", data);
        },
        setSelectedCard({ commit }, data) {
            commit("setSelectedCard", data);
        },
        setShowUserModal({ commit }, data) {
            commit("setShowUserModal", data);
        },
        setShowQrCodeModal({ commit }, data) {
            commit("setShowQrCodeModal", data);
        },
        setSelectedCardBalance({ commit }, data) {
            commit("setSelectedCardBalance", data);
        },
        setParticipants({ commit }, data) {
            commit("setParticipants", data);
        },
        reset({ commit }, data) {
            commit("reset", data);
        },
        setTranslations({ commit }, data) {
            commit("setTranslations", data);
        },
        setConfigVars({ commit }, data) {
            commit("setConfigVars", data);
        },
    },
    getters: {
        getPrograms: (state) => state.programs,
        getBudgets: (state) => state.budgets,
        getPermissionGroups: (state) => state.config_vars.permission_groups,
        isLoggedIn() {
            return !!localStorage.getItem("token");
        },
        token() {
            return localStorage.getItem("token");
        },
        authStatus: (state) => state.status,
        getUser: (state) => state.user,
        translate: (state) => (payload) => {
            if (state.translations.length != 0) {
                let result = state.translations.filter(
                    (translation) =>
                        translation.key == payload && translation.language == state.language
                );

                if (result.length != 0) {
                    return result[0].value;
                }

                return payload;
            } else {
                return payload;
            }
        },
        selectedCard: (state) => state.selected_card,
        cards: (state) => state.cards,
        contrastMode: (state) => state.contrast_mode,
        showUserModal: (state) => state.show_user_modal,
        showQrCodeModal: (state) => state.show_qrcode_modal,
        getParticipants: (state) => state.participants,
        getTranslations: (state) => state.translations,
        getConfigVars: (state) => state.config_vars,
    },
    modules: {
        requestIndexSettings,
    },
    plugins: [new VuexPersistence().plugin],
});
