<template>
    <div class="text-center">
        <v-menu :close-on-content-click="false" offset-x :rounded="$config.shaped_widgets" v-model="filter">
            <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on">
                    <v-icon>mdi-filter</v-icon>
                </v-btn>
            </template>

            <v-card>
                <v-toolbar flat>
                    <v-icon class="mr-3">mdi-filter</v-icon>
                    {{ $t("filter") }}
                    <v-spacer></v-spacer>
                    <v-btn icon @click="filter = false"><v-icon>mdi-close</v-icon></v-btn>
                </v-toolbar>
                <v-card-text class="mt-0 pt-0">
                    <template v-for="(field, index) in data">
                        <v-switch v-if="field.type == 'boolean'" :key="index" v-model="field.string"
                            :label="field.label" @change="emitString()"></v-switch>
                        <v-select v-if="field.type == 'select'" :key="index" v-model="field.string" :label="field.label"
                            :items="field.options" outlined @input="emitString()" :multiple="field.multiple"
                            clearable></v-select>
                        <v-text-field v-if="field.type == 'text-field'" :key="index" v-model="field.string"
                            :label="field.label" outlined v-debounce:300ms="emitString"></v-text-field>
                    </template>
                    <!-- Nieuw jaar-select veld -->
                    <v-select label="Jaar" :items="yearOptions" v-model="selectedYear" outlined clearable
                        @input="emitString" />
                </v-card-text>
            </v-card>
        </v-menu>
    </div>
</template>

<script>
export default {
    props: ["options"],
    data() {
        return {
            show_filter: false,
            data: [],
            filter: false,
            selectedYear: new Date().getFullYear(), // Default dit jaar
        };
    },
    computed: {
        yearOptions() {
            const currentYear = new Date().getFullYear();
            const maxYear = currentYear + 1; // Maximaal 1 jaar in de toekomst
            const minYear = currentYear - 10; // Maximaal 10 jaar terug
            const years = [];
            for (let year = maxYear; year >= minYear; year--) {
                years.push(year);
            }
            return years;
        },
    },
    created() {
        this.data = this.options;
    },
    methods: {
        emitString() {
            let string = "";

            this.data.forEach((field, index) => {
                let prefix = index === 0 ? "?" : "&";
                let field_string = field.string ? field.string : "";

                string += prefix + field.name + "=" + field_string;
            });

            // Voeg het geselecteerde jaar toe aan de querystring
            if (this.selectedYear) {
                const prefix = string.length === 0 ? "?" : "&";
                string += `${prefix}year=${this.selectedYear}`;
            }

            this.$emit("refresh", string);
        },
    },
};
</script>

<style></style>
