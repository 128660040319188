export default {
    namespaced: true,
    state: {
        search: "",
        itemsPerPage: 25,
        page: 1,
        approvalFilter: [],
        extensionFilter: [],
        year: new Date().getFullYear(),
    },
    mutations: {
        SET_SEARCH(state, search) {
            state.search = search;
        },
        SET_ITEMS_PER_PAGE(state, itemsPerPage) {
            state.itemsPerPage = itemsPerPage;
        },
        SET_PAGE(state, page) {
            state.page = page;
        },
        SET_APPROVAL_FILTER(state, filter) {
            state.approvalFilter = filter;
        },
        SET_EXTENSION_FILTER(state, filter) {
            state.extensionFilter = filter;
        },
        SET_YEAR_FILTER(state, filter) {
            state.yearFilter = filter;
        }
    },
    actions: {
        updateSettings({ commit }, { search, itemsPerPage, page, approvalFilter, extensionFilter, yearFilter }) {
            commit("SET_SEARCH", search);
            commit("SET_ITEMS_PER_PAGE", itemsPerPage);
            commit("SET_PAGE", page);
            commit("SET_APPROVAL_FILTER", approvalFilter);
            commit("SET_EXTENSION_FILTER", extensionFilter);
            commit("SET_YEAR_FILTER", yearFilter);
        },
    },
};
